import React, { useState, useEffect } from "react"
import { graphql } from "gatsby"
import Section from "../components/global/section"
import Hero from "../sections/hero/hero"
import Content from "../sections/content"
import Thrive from "../sections/thrive/thrive"
import Born from "../sections/born/born"
import Collaborative from "../sections/collaborative/collaborative"
import Working from "../sections/working/working"
import Listen from "../sections/listen/listen"
import Think from "../sections/think/think"
import Together from "../sections/together/together"
import Solutions from "../sections/solutions/solutions"
import People from "../sections/our-people/people"
import Work from "../sections/our-work/work"
import Layout from "../components/global/layout"
import { Waypoint } from "react-waypoint"
import { Element } from "react-scroll"
import ReactFullpage from "@fullpage/react-fullpage"
import Footer from "../components/footer/footer"
import ContactSection from "../components/contact/contact"

const Index = ({
  data: {
    hero,
    thrive,
    born,
    collaborative,
    working,
    listen,
    think,
    together,
    solutions,
    caseStudies,
    categories,
    datoCmsHomeSeo,
    datoCmsContactPage,
    staff,
    ourPeople,
  },
}) => {
  // console.log("staff from index", staff)
  const [darkNav, setDarkNav] = useState(true)

  const [scrollInst, setScrollInst] = useState(false)
  const [yPos, setYPos] = useState(true)
  const [windowWidth, setWindowWidth] = useState(0)

  const [showBackToTop, setShowBackToTop] = useState(false)

  const setNav = (bool) => {
    setDarkNav(bool)
  }

  const [name, setName] = useState(``)

  useEffect(() => {
    if (typeof window !== `undefined`) {
      const queryString = window.location.search
      const urlParams = new URLSearchParams(queryString)

      if (urlParams) {
        setName(urlParams.get("name"))
      }
    }
  }, [name])

  const onLeave = (origin, destination, direction) => {
    if (document.readyState === "complete") {
      document.body.classList.remove("nav--light")
      document.body.classList.remove("nav--dark")
      if (
        destination.index == 0 || 
        destination.index == 2  
        // destination.index == 5 ||
        // destination.index == 3 
        // destination.index == 8
      ) {
        document.body.classList.add("nav--light")
      } else {
        document.body.classList.add("nav--dark")
      }
      // arguments are mapped in order of fullpage.js callback arguments do something
      // with the event

      if (destination.index == 0) {
        setYPos(0)
        setShowBackToTop(false)
      } else {
        setShowBackToTop(true)
        if (scrollInst) {
          setYPos(scrollInst.getScrollY())
        } else {
          setYPos(500)
        }
      }
    }
  }

  const handleBackToTop = () => {
    scrollInst.moveTo(1)
  }

  const handleMoveToStory = () => {
    scrollInst.moveTo(2)
  }

  return (
    <>
      <Layout
        showBackToTop={showBackToTop}
        handleBackToTop={handleBackToTop}
        isDarkNav={darkNav}
        setNav={setNav}
        sideNav
        seo={datoCmsHomeSeo.seoMetaTags}
        yPos={yPos}
      >
        <ReactFullpage
          //fullpage options
          licenseKey={"SK7R7-05SP8-G2GI9-5TJNK-DULJM"}
          // menu={"#menu"}
          scrollingSpeed={700} /* Options here */
          anchors={[
            "hero",
            "story",
            "work",
            "solutions",
            "contact",
            "solutions",
            "work",
            "contact",
            "footer",
          ]}
          navigation={false}
          navigationPosition={"right"}
          slidesNavigation={true}
          paddingTop={0}
          onLeave={onLeave}
          beforeLeave={onLeave}
          afterLoad={onLeave}
          afterRender={() => {
            // 'fullpage_api' is not defined.
            // It works in codesandbox but my vscode.
            //fullpage_api.setAllowScrolling(false);
            if (typeof window !== `undefined`) {
              setWindowWidth(window.innerWidth)
              setScrollInst(window.fullpage_api)
            }
            // It works both in codesandbox and my vscode.
            //window.fullpage_api.setAllowScrolling(false);
          }}
          afterResize={(w, h) => {
            setWindowWidth(w)
          }}
          paddingBottom={0}
          showActiveTooltip={true}
          navigationTooltips={[
            ,
            "Our Story",
            "Our Work",
            "Capabilities",
            "Contact",
            ,
            ,
          ]}
          fitToSection={false}
          bigSectionsDestination={"top"}
          responsiveWidth={1200}
          responsiveHeight={800}
          render={({ state, fullpageApi }) => {
            return (
              <ReactFullpage.Wrapper>
                <Section id={"kia-ora"}>
                  <Hero
                    handleMoveToStory={handleMoveToStory}
                    content={hero}
                    name={name}
                    isActive={state.destination?.anchor === "hero"}
                    windowWidth={windowWidth}
                  />
                </Section>
                <Section id={"story"}>
                  <Thrive content={thrive} />
                </Section>
                {/* <Section id={"born"}>
                  <Born content={born} />
                </Section> */}
                {/* <Section id={"collaborative"} grey>
                  <Collaborative content={collaborative} />
                </Section> */}
                {/* <Section id={"working"}>
                  <Working content={working} />
                </Section> */}
                {/* <Section id={"listen"}>
                  <Listen content={listen} />
                </Section>
                <Section id={"think"} grey>
                  <Think content={think} />
                </Section>
                <Section id={"together"}>
                  <Together content={together} />
                </Section> */}

                {/* <Section id={"staff"} grey>
                  <People ourPeople={ourPeople} staff={staff.nodes} />
                </Section> */}
                {/* <Section darkGrey id={"work"}>
                  <Work
                    caseStudies={caseStudies.nodes}
                    categories={categories.nodes}
                  />
                </Section>
                <Section dark id={"solutions"}>
                  <Solutions content={solutions} />
                </Section>

                <Section id={"contact"}>
                  <ContactSection
                    title={datoCmsContactPage.title}
                    description={datoCmsContactPage.description}
                    locations={datoCmsContactPage.locations}
                  />
                </Section> */}
                <Section dark id={"footer"}>
                  <Footer />
                </Section>
              </ReactFullpage.Wrapper>
            )
          }}
        />
      </Layout>
    </>
  )
}

export default Index

export const query = graphql`
  {
    datoCmsHomeSeo {
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
    }
    datoCmsContactPage {
      id: originalId
      title
      description
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      locations {
        title
      }
    }
    hero: datoCmsHeroSection {
      heading
      backgroundImage {
        gatsbyImageData(
          layout: FULL_WIDTH
          imgixParams: { w: "1920", fit: "crop", q: 100 }
        )
        alt
        url
      }
      backgroundVideo {
        video {
          streamingUrl
        }
        url
      }
      backgroundVideoMobile {
        video {
          streamingUrl
        }
        url
      }
    }
    thrive: datoCmsThriveSection {
      content
      heading
      subHeading
      image {
        gatsbyImageData(
          layout: FULL_WIDTH
          imgixParams: { w: "570", h: "344", fit: "crop", q: 95 }
        )
        alt
      }
    }
    born: datoCmsBornSection {
      contentLeft
      contentRight
      heading
      subHeading
      backgroundImage {
        gatsbyImageData(
          layout: FULL_WIDTH
          imgixParams: { w: "1920", fit: "crop", q: 95 }
        )
        alt
      }
    }
    collaborative: datoCmsCollaborativeSection {
      contentLeft
      contentMiddle
      contentRight
      heading
      subHeading
      image {
        gatsbyImageData(
          layout: FULL_WIDTH
          imgixParams: { w: "820", h: "240", fit: "crop", q: 100 }
        )
        alt
      }
    }
    working: datoCmsWorkingSection {
      content
      heading
      iconsHeading
      subHeading
      icons {
        heading
        icon {
          url
        }
      }
    }
    listen: datoCmsListenSection {
      heading
      subHeading
      content
      image {
        gatsbyImageData(layout: CONSTRAINED, imgixParams: { w: "572", q: 95 })
        alt
      }
    }
    think: datoCmsThinkSection {
      heading
      subHeading
      content
      image {
        gatsbyImageData(layout: CONSTRAINED, imgixParams: { w: "572", q: 95 })
        alt
      }
    }
    together: datoCmsTogetherSection {
      heading
      subHeading
      content
      image {
        gatsbyImageData(layout: CONSTRAINED, imgixParams: { w: "572", q: 95 })
        alt
      }
    }
    solutions: datoCmsSolutionsSection {
      heading
      subHeading
      backgroundImage {
        gatsbyImageData(
          layout: FULL_WIDTH
          imgixParams: { w: "1920", fit: "crop", q: 100 }
        )
        alt
      }
      columns {
        title
        description
        items {
          text
        }
      }
    }
    caseStudies: allDatoCmsWork(sort: { order: ASC, fields: position }) {
      nodes {
        position
        title
        category {
          title
        }
        excerpt
        id: originalId
        heroImage {
          gatsbyImageData(
            layout: CONSTRAINED
            imgixParams: { w: "420", h: "240", fit: "crop", q: 90 }
          )
          alt
          url
          format
        }
        thumbnail {
          gatsbyImageData(
            layout: CONSTRAINED
            imgixParams: { w: "420", h: "240", fit: "crop", q: 90 }
          )
          alt
          url
          format
        }
      }
    }
    staff: allDatoCmsStaff {
      nodes {
        name
        jobTitle
        originalId
        id
        image {
          gatsbyImageData(
            layout: CONSTRAINED
            imgixParams: { w: "235", h: "215", fit: "crop", q: 90 }
          )
        }
      }
    }

    ourPeople: datoCmsStaffSection {
      heading
      content
    }

    categories: allDatoCmsWorkCategory {
      nodes {
        title
        originalId
      }
    }
  }
`
