import { GatsbyImage } from "gatsby-plugin-image"
import React, { useEffect, useRef } from "react"
import Container from "../../components/container/container"
import style from "./hero.mod.scss"
import { motion } from "framer-motion"
// import ArrowRightBlack from "../../components/icons/arrow-right-black"
import Scroll from "../../components/icons/scroll"

const Hero = ({
  content: { heading, backgroundImage, backgroundVideo, backgroundVideoMobile },
  handleMoveToStory,
  name,
  isActive,
  windowWidth,
}) => {
  const headingVariants = {
    offscreen: {
      x: 30,
      opacity: 0,
    },
    onscreen: {
      x: 0,
      opacity: 1,
      transition: {
        type: "spring",
        bounce: 0.2,
        duration: 1.4,
        delay: 1.2,
      },
    },
  }

  const scrollButtonVariants = {
    offscreen: {
      opacity: 0,
    },
    onscreen: {
      opacity: 1,
      transition: {
        type: "spring",
        bounce: 0.6,
        duration: 0.8,
        delay: 1.5,
      },
    },
  }

  const videoRef = useRef(null)

  useEffect(() => {
    let retryTimeout

    const playVideo = () => {
      if (videoRef.current) {
        videoRef.current.muted = true // Just in case the browser requires it
        const playPromise = videoRef.current.play()
        if (playPromise !== undefined) {
          playPromise
            .then(() => {
              // Success — video played
            })
            .catch((error) => {
              console.warn("Initial autoplay failed:", error)

              // Retry once more after 1 second
              retryTimeout = setTimeout(() => {
                videoRef.current?.play().catch((retryError) => {
                  console.warn("Retry autoplay failed:", retryError)
                })
              }, 1000)
            })
        }
      }
    }

    if (isActive) {
      playVideo()
    } else if (videoRef.current) {
      videoRef.current.pause()
    }

    // Cleanup retry timeout if the component unmounts or isActive changes
    return () => clearTimeout(retryTimeout)
  }, [isActive])

  return (
    <div className={style.hero}>
      {/* <div className={style.hero__scroll_button}>
        <motion.div
          variants={scrollButtonVariants}
          initial="offscreen"
          whileInView="onscreen"
          viewport={{ once: true, amount: 0.2 }}
        >
          <div
            onClick={() => handleMoveToStory()}
            className={style.hero__scroll_button_bg}
          >
            <Scroll />
          </div>
        </motion.div>
      </div> */}
      {/* <div className={style.hero_customflicker}></div> */}
      <div className={style.hero__image}>
        {!backgroundVideo && (
          <GatsbyImage
            image={backgroundImage.gatsbyImageData}
            alt="Harvey Cameron hero image"
          />
        )}
        {backgroundVideo && (
          <>
            <video
              poster={backgroundImage.url}
              autoPlay
              playsInline
              muted
              preload="auto"
              loop
              ref={videoRef}
            >
              {windowWidth >= 992 ? (
                <>
                  {backgroundVideo.video?.streamingUrl && (
                    <source
                      src={backgroundVideo.video.streamingUrl}
                      type="video/mp4"
                    />
                  )}
                  {backgroundVideo.url && (
                    <source src={backgroundVideo.url} type="video/mp4" />
                  )}
                </>
              ) : (
                <>
                  {backgroundVideoMobile.video?.streamingUrl && (
                    <source
                      src={backgroundVideoMobile.video.streamingUrl}
                      type="video/mp4"
                    />
                  )}
                  {backgroundVideoMobile.url && (
                    <source src={backgroundVideoMobile.url} type="video/mp4" />
                  )}
                </>
              )}
            </video>
            {/* <div className={style.hero__overlay} /> */}
          </>
        )}
      </div>
      {/* <Container fullHeight gutters width={"large"}>
        <div className={style.hero__content}>
          <motion.div
            variants={headingVariants}
            initial="offscreen"
            whileInView="onscreen"
            viewport={{ once: true, amount: 0.2 }}
          >
            <h1>
              {heading}
            </h1>
          </motion.div>
        </div>
      </Container> */}
    </div>
  )
}

export default Hero
